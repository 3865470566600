@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: "Rajdhani";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0c0c0c;
  color: white;
}

::-webkit-scrollbar {
  display: none;
}
